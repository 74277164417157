exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-quirk-cars-in-quincy-ma-tsx": () => import("./../../../src/pages/about-quirk-cars-in-quincy-ma.tsx" /* webpackChunkName: "component---src-pages-about-quirk-cars-in-quincy-ma-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-car-loans-in-quincy-ma-tsx": () => import("./../../../src/pages/car-loans-in-quincy-ma.tsx" /* webpackChunkName: "component---src-pages-car-loans-in-quincy-ma-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-meet-daniel-j-quirk-tsx": () => import("./../../../src/pages/meet-daniel-j-quirk.tsx" /* webpackChunkName: "component---src-pages-meet-daniel-j-quirk-tsx" */),
  "component---src-pages-quirk-warranty-tsx": () => import("./../../../src/pages/quirk-warranty.tsx" /* webpackChunkName: "component---src-pages-quirk-warranty-tsx" */),
  "component---src-pages-sell-and-trade-tsx": () => import("./../../../src/pages/sell-and-trade.tsx" /* webpackChunkName: "component---src-pages-sell-and-trade-tsx" */),
  "component---src-templates-cars-tsx": () => import("./../../../src/templates/cars.tsx" /* webpackChunkName: "component---src-templates-cars-tsx" */),
  "component---src-templates-detail-tsx": () => import("./../../../src/templates/detail.tsx" /* webpackChunkName: "component---src-templates-detail-tsx" */)
}

